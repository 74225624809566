export const ORIGINAL_IMAGE_WIDTH = 'original'

export const SCROLL_CONSTANT = {
  SMOOTH: 'smooth',
  NEAREST: 'nearest',
  CENTER: 'center'
}

export const ARROW_ACTION_TYPE = {
  PREVIOUS: 'prev',
  NEXT: 'next'
}

export const IMAGE_INDEX_TYPE = {
  CURRENT: 'current',
  FIRST: 'first',
  LAST: 'last'
}

export const STATIC_IMAGE_PATH = '/assets/images/richTextWithImage.png'