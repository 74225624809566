import { COMMON_LAYOUT_TYPE } from "../Testimonials/Constants";
import CarouselGridLayout from "./CarouselGridLayout";
import ThumbnailLayout from "./ThumbnailLayout";

function GalleryWidget(props) {
  const {listPresentation,  cardFrame} = props
    function renderLayoutType() {
        switch(listPresentation.layoutName) {
          case COMMON_LAYOUT_TYPE.ONE:
          case COMMON_LAYOUT_TYPE.TWO:
          return <CarouselGridLayout {...props}/>
          case COMMON_LAYOUT_TYPE.THREE:
         return <ThumbnailLayout cardFrame = {cardFrame} />
        }
    }
    return (
       renderLayoutType()
    )
}

export default GalleryWidget;