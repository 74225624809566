import { Modal } from "@mui/material";
import ThumbnailLayout from "./ThumbnailLayout";
import { isMobileDevice } from "src/components/WidgetMaker/WidgetDnD/isMobileDevice";

function RenderThumnailModal(props) {
  const { cardFrame, config, setConfig } = props;
  const isMobile = isMobileDevice();
  function handleClose() {
    setConfig((prev) => {
      return {
        ...prev,
        isVisible: false
      }
    })
  }
  return (
    <Modal
      open={config.isVisible}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
      }}
    >
      <div className="tw-max-w-[1500px] tw-bg-transparent tw-flex tw-justify-center tw-relative">
        <ThumbnailLayout cardFrame={cardFrame} currentIndex={config.currIndex} isThumbnailModal={true} onCloseClick={handleClose} />
        {!isMobile && <div onClick={handleClose} className={`ic-cross-bg-black-lg wb-sprite tw-fixed tw-top-[20px] tw-right-[20px]`}></div>}
      </div>
    </Modal>
  )
}

export default RenderThumnailModal;