import classNames from 'classnames';
import { ArrowLeftV1, ArrowLeftV2, ArrowLeftV3, ArrowRightV1, ArrowRightV2 } from './ArrowIcons';
import { twMerge } from 'tailwind-merge';

interface _IArrowIcon {
  onClick?: any;
  arrowDirection?: 'left' | 'right';
  arrowType?: 'v1' | 'v2' | 'v3';
  imageProps?: any;
}

export const ArrowIcon = (props: _IArrowIcon) => {
  const { onClick = () => {}, imageProps = {}, arrowDirection, arrowType } = props;
  let Element = null;

  const _imageProps = {
    height: 36,
    width: 36,
    style: {},
    className: '',
    bgColor: '',
    bgOpacity: '',
    arrowColor: '',
    isShadowTypeIcon : false,
    ...imageProps,
  };

  switch (arrowType) {
    case 'v1':
      Element = arrowDirection === 'left' ?  ArrowLeftV1 : ArrowRightV1;
      break;
    case 'v2':
      Element = arrowDirection === 'left' ? ArrowLeftV2 : ArrowRightV2;
      break;
    case 'v3': 
      Element = ArrowLeftV3 
      break;
    default:
      Element = null;
  }

  if (!Element) return null;
  return (
    <div
      className={twMerge("tw-pointer-events-auto tw-cursor-pointer", _imageProps.className)}
      style={{ height: imageProps.height, width: imageProps.width }}
      onClick={onClick}
    >
      <Element {..._imageProps} />
    </div>
  );
};

export const ArrowCouple = ({
  handleNextClick = () => {},
  handlePrevClick = () => {},
  isVisible = false,
  arrowType = 'v1',
  className = '',
  imageProps = {},
  style = {},
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <div
      style={style}
      className={classNames(
        'tw-absolute tw-top-[50%] tw-flex tw-w-[100%] tw-translate-y-[-50%] tw-items-center tw-justify-between tw-px-[40px]',
        className
      )}
    >
      <ArrowIcon
        onClick={handlePrevClick}
        arrowDirection="left"
        arrowType={arrowType as any}
        imageProps={imageProps}
      />
      <ArrowIcon
        onClick={handleNextClick}
        arrowDirection="right"
        arrowType={arrowType as any}
        imageProps={imageProps}
      />
    </div>
  );
};
