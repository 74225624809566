import React, { useEffect, useRef, useState } from "react";
import { ArrowIcon } from "../../../Carousel/components/Arrows";
import { isMobileDevice } from "src/components/WidgetMaker/WidgetDnD/isMobileDevice";
import { Modal } from "@mui/material";
import classNames from "classnames";
import { getCurrentClass } from "../../../CardFrameCollection/utils";
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { ARROW_ACTION_TYPE, IMAGE_INDEX_TYPE, SCROLL_CONSTANT, STATIC_IMAGE_PATH } from "./constant";
import { getVisibleImages } from "./helper";

const ARROW_STYLE = {
  arrowType: 'v3' as 'v1' | 'v2' | 'v3',
  arrowStyle: {
    bgColor: '#FFFFFF',
    bgOpacity: 1,
    arrowColor: '#000000',
  },
}

const ThumbnailLayout = (props) => {
  const { cardFrame, currentIndex = 0, isThumbnailModal = false, onCloseClick } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [fromModal, setFromModal] = useState(isThumbnailModal);
  const [showThumbnailModalMobile, setShowThumbnailModalMobile] = useState(true);
  const imageRef = useRef(null);
  const thumnailRef = useRef(null)
  const imagesList = getVisibleImages(cardFrame?.imagesData);
  const isMobile = isMobileDevice();
  const containerRef = useRef(null);
  let touchStartX = 0;
  let touchEndX = 0;

  useEffect(() => {
    setActiveIndex(currentIndex)
  }, [currentIndex])

  useEffect(() => {
    setFromModal(isThumbnailModal);
  }, [isThumbnailModal])

  useEffect(() => {
    // Both images and thumbnails should scoll if there is changes in active index
    const childImages = imageRef?.current?.children;
    const thumbnailImage = thumnailRef?.current?.children
    if (childImages?.[activeIndex]) {
      childImages[activeIndex].scrollIntoView({
        behavior: SCROLL_CONSTANT.SMOOTH,
        block: SCROLL_CONSTANT.NEAREST,
      });
    }

    if (thumbnailImage?.[activeIndex]) {
      thumbnailImage[activeIndex].scrollIntoView({
        behavior: SCROLL_CONSTANT.SMOOTH,
        inline: SCROLL_CONSTANT.CENTER, // Ensures it stays in view
        block: SCROLL_CONSTANT.NEAREST
      });
    }
  }, [activeIndex]);

  function handleImageClick() {
    if (isMobile) {
      setFromModal(true);
      setShowThumbnailModalMobile(true);
    }
  }

  function renderImage(imageData) {
    const isActiveImage = imageData.currentIndex === activeIndex;
    const activeImageStyle = {
      maxWidth: !isMobile ? '1200px' : '100%',
      maxHeight: !isMobile ? window.innerHeight * .7 > 600 ? '600px' : (window.innerHeight * .7) : '250px',
      height: '100%',
      width: '100%'
    }
    const nonActiveImage = {
      width: !isMobile ? '180px' : '20px',
      height: !isMobile ? '284px' : '160px',
      opacity: '.7'
    }
    return (
      <div onClick={handleImageClick} style={isActiveImage ? activeImageStyle : nonActiveImage} className=" tw-overflow-hidden">
        <img className={classNames("tw-h-full tw-w-full tw-bg-black tw-object-cover",
          contentAnimationStyle[getCurrentClass(cardFrame?.hoverEffect)])}
          style={isActiveImage ? activeImageStyle : nonActiveImage} src={imageData.url || STATIC_IMAGE_PATH} />
      </div>
    )
  }

  function handleImageNextAndPrev(type: string) {
    let updatedActiveIndex = activeIndex
    if (type === ARROW_ACTION_TYPE.PREVIOUS) {
      updatedActiveIndex = activeIndex === 0 ? imagesList?.length - 1 : (updatedActiveIndex - 1)
    }
    else {
      updatedActiveIndex = activeIndex === imagesList?.length - 1 ? 0 : (updatedActiveIndex + 1)
    }
    setActiveIndex(updatedActiveIndex)
  }

  const handleTouchStart = (e) => {
    touchStartX = e?.touches[0]?.clientX;
  };

  const handleTouchEnd = (e) => {
    touchEndX = e?.changedTouches[0]?.clientX;
    handleSwipe();
  };

  const handleSwipe = () => {
    if (touchStartX - touchEndX > 50) {
      setActiveIndex((prev) => (prev + 1) % imagesList.length);
    } else if (touchEndX - touchStartX > 50) {
      setActiveIndex((prev) => (prev - 1 + imagesList.length) % imagesList.length);
    }
  };

  function handleThumbnailClick(index: number) {
    setActiveIndex(index);
  }

  function handleCloseClick() {
    setShowThumbnailModalMobile(false);
    onCloseClick?.();
  }

  function getImageData(type, index) {
    switch (type) {
      case IMAGE_INDEX_TYPE.CURRENT:
        return {
          currentIndex: index,
          url: imagesList[index]?.imageUrl,
        };
      case IMAGE_INDEX_TYPE.FIRST:
        return {
          currentIndex: imagesList?.length - 1,
          url: imagesList[imagesList?.length - 1]?.imageUrl,
        }
      case IMAGE_INDEX_TYPE.LAST:
        return {
          currentIndex: 0,
          url: imagesList[0]?.imageUrl,
        }
    }
  }

  // Render images
  function renderLayoutImages() {
    return (
      <div className="tw-w-[100%] tw-flex tw-items-center tw-justify-center">
        <div style={{ width: !isMobile ? (containerRef?.current?.clientWidth || '') : '' }}
          className={`tw-flex tw-items-center tw-justify-center tw-max-w-[1240px]`}>
          {!isMobile && <ArrowIcon
            onClick={() => handleImageNextAndPrev(ARROW_ACTION_TYPE.PREVIOUS)}
            arrowDirection="left"
            arrowType={ARROW_STYLE.arrowType}
            imageProps={ARROW_STYLE.arrowStyle}
          />}
          <div
            style={{ height: !isMobile ? window.innerHeight * .7 : '' }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            ref={imageRef}
            className={`tw-w-[100%] ${!isMobile ? 'tw-gap-[20px] tw-max-h-[600px]' : 'tw-max-w-[100%] tw-gap-[12px] tw-h-[250px]'} tw-flex tw-items-center tw-justify-center`}>
            {imagesList?.map((data, index) => {
              return (
                <div
                  key={data.imageUrl + index}
                  className={`tw-transition-opacity tw-duration-500 ${activeIndex === index ? 'tw-opacity-100 tw-visible' : 'tw-opacity-0 tw-invisible tw-absolute'}
                  `}
                >
                  <div className={`tw-flex tw-items-center tw-justify-center ${!isMobile ? 'tw-gap-[20px]' : 'tw-gap-[12px]'} `}>
                    {renderImage(getImageData('current', index))}
                  </div>
                </div>
              );
            })}
          </div>
          {!isMobile && <ArrowIcon
            onClick={() => handleImageNextAndPrev(ARROW_ACTION_TYPE.NEXT)}
            arrowDirection="right"
            arrowType={ARROW_STYLE.arrowType}
            imageProps={{...ARROW_STYLE.arrowStyle, className: 'tw-rotate-180'}}
          />}
        </div>
      </div>
    )
  }
  // Render thumbnails
  function renderThumbnail() {
    return (
      <div className="tw-flex tw-items-center tw-justify-center">
        <div className={`tw-flex tw-items-center tw-mt-[24px] tw-justify-center ${isMobile ? 'tw-w-[100%]' : ''}`}>
          <div
            ref={thumnailRef}
            className="no-scrollbar tw-relative tw-flex tw-gap-[12px] tw-items-center tw-mx-auto 
        tw-overflow-x-auto tw-overflow-y-hidden tw-whitespace-nowrap tw-pl-[16px] pr-[2px]"
            style={{
              maxWidth: !isMobile ? "800px" : '100%',
              scrollbarWidth: "none",
            }}
          >
            {imagesList?.map((data, index) => {
              const isActiveIndex = index === activeIndex;
              return (
                <div
                  key={index}
                  className={`tw-rounded-[6px] ${isActiveIndex ? !isMobile ? 'tw-w-[68px] tw-h-[68px]' : 'tw-w-[56px] tw-h-[56px]' :
                    !isMobile ? 'tw-w-[56px] tw-h-[56px]' : 'tw-w-[48px] tw-h-[48px] tw-opacity-80'} tw-flex tw-items-center tw-justify-center 
              tw-cursor-pointer tw-shrink-0`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={(data?.imageUrl || STATIC_IMAGE_PATH)}
                    className="tw-object-cover tw-rounded-[6px] tw-h-full tw-w-full"
                    style={{
                      border: isActiveIndex ? "1px solid ##111C3633" : "",
                      boxShadow: isActiveIndex ? '0px 0px 4px 0px #111C365C' : ""
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  function renderMobileModalImage() {
    return (
      <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        className="tw-w-[100%] max-h-[400px]">
        <img src={imagesList[activeIndex]?.imageUrl || STATIC_IMAGE_PATH} width={"100%"} />
      </div>
    )
  }

  function renderThumnailModal() {
    return (
      <Modal
        open={showThumbnailModalMobile}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'transparent',
        }}
      >
        <div className="tw-relative tw-h-[100%] tw-w-[100%] tw-bg-transparent tw-flex tw-flex-col tw-justify-center">
          {renderMobileModalImage()}
          <div className="tw-absolute tw-bottom-20 tw-left-0 tw-w-[100%] tw-overflow-x-auto tw-whitespace-nowrap">
            {renderThumbnail()}
          </div>
          <div onClick={handleCloseClick} className="ic-cross-bg-black-lg wb-sprite tw-absolute tw-top-[20px] tw-right-[20px]"></div>
        </div>
      </Modal>
    )
  }

  return (
    <div
      ref={containerRef}
      className={`tw-w-[100%] tw-relative ${isMobile ? 'tw-h-[350px]' : ''} ${fromModal ? 'tw-w-[90vw]' : ''}`}>
      {(showThumbnailModalMobile && fromModal && isMobile) ? renderThumnailModal() : (
        <>
          {renderLayoutImages()}
          <div className={`${isMobile ? 'tw-absolute tw-bottom-0 tw-left-0 tw-w-[100%] tw-overflow-x-auto tw-whitespace-nowrap' : ''}`}>
            {renderThumbnail()}
          </div>
        </>
      )}
    </div>
  )
}

export default ThumbnailLayout;