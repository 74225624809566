type LayoutStatus = {
  [key: string]: boolean;
};

const MAX_LAYOUTS = 10;
const LAYOUT_NAME_PREFIX = 'layout';
const LAYOUT_KEY_PREFIX = 'isLayout';

export const useLayoutTypes = ({ listPresentationConfig }) => {
  const layoutTypes: LayoutStatus = {};

  for (let layoutNum = 0; layoutNum < MAX_LAYOUTS; layoutNum++) {
    const layoutKey = LAYOUT_KEY_PREFIX + layoutNum;
    const layoutName = LAYOUT_NAME_PREFIX + layoutNum;

    layoutTypes[layoutKey] = listPresentationConfig?.layoutName === layoutName;
  }

  return layoutTypes;
};
